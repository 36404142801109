import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PageSection from '../components/page-section'
import VideoSlider from '../components/video-slider'
import parse from "html-react-parser"

const sanitizeExcerpt = (excerpt) => {
  return excerpt.replace(/(<([^>]+)>)/gi, "");
};

// function to extract ID from the end of the vimeo url
const extractVimeoId = (url) => {
  if (!url) {
    return null;
  }
  const match = url.match(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/[\w]+\/|album\/\d+\/video\/|video\/|)(\d+)/);
  return match ? match[1] : null;
};

const BlogPostTemplate = ({ data: { previous, next, post, similarPosts } }) => {

  const videoExcerpt = sanitizeExcerpt(post.videoExcerpt || post.excerpt);

  // assigning the extracted id to the vimeoId variable
  const vimeoId = extractVimeoId(post.vimeoPlayerUrl);

  // hardcoded vimeo url to test with while waiting for video mapping on backend - this worked!
  // const hardcodedVimeoUrl = "https://vimeo.com/1040383043"
  
  // Logging the native video URL   
  console.log("Video URL:", post.videoUrl); 
  // Logging the extracted Vimeo ID to see if the vimeo urls are coming through
  console.log('Vimeo ID:', vimeoId); 

  return (
    <Layout pageTitle={post.title} metaDescription={videoExcerpt}>

      <div className="hero-video-container">
        <div className="hero-video-ratio"></div>
        <div className="hero-video-player">
          {vimeoId ? (
            <iframe
              src={`https://player.vimeo.com/video/${vimeoId}`}
              width="100%"
              height="100%"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title={post.title}
              loading="lazy"
            ></iframe>
          ) : null}

          {!vimeoId && post.videoUrl ? (
            <video controls poster={post.featuredImage?.node?.sourceUrl} width="100%" height="100%">
              <source src={post.videoUrl} />
              {post.videoSubtitles ? (
                <track
                  label="English subtitles"
                  kind="subtitles"
                  srcLang="en"
                  src={post.videoSubtitles}
                  default
                />
              ) : (
                <p>No captions available</p>
              )}
            </video>
          ) : null}
        </div>
      </div>

      <PageSection title={post.title} sectionClass="">
        <article className="video-post" itemScope itemType="http://schema.org/Article">

          {!!post.content && (
            <section itemProp="articleBody">
              {post.videoResourceList && post.videoResourceList.length > 0 ? (
                <a href="#resources" className="jump-to-resources">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="48" fill="currentColor" role="img">
                    <path d="M385.77 403.57a8.52 8.52 0 0 0-12.07 0l-19.57 19.56v-98.86a8.54 8.54 0 0 0-17.06 0v98.86l-19.57-19.56a8.52 8.52 0 1 0-12.07 12.06l34.14 34.14a8.51 8.51 0 0 0 12.06 0l34.14-34.14a8.52 8.52 0 0 0 0-12.06z" />
                    <path d="M345.6 256c-70.58 0-128 57.42-128 128s57.42 128 128 128 128-57.42 128-128-57.42-128-128-128zm0 238.93c-61.17 0-110.93-49.76-110.93-110.93s49.76-110.93 110.93-110.93S456.53 322.83 456.53 384 406.77 494.93 345.6 494.93zM226.13 469.33H55.47V409.6a8.54 8.54 0 0 0-17.07 0v68.27a8.54 8.54 0 0 0 8.53 8.53h179.2a8.54 8.54 0 0 0 0-17.07zM46.93 366.93a8.54 8.54 0 0 0 0 17.07h.09c4.7 0 8.49-3.82 8.49-8.53s-3.87-8.54-8.58-8.54z" />
                    <path d="M394.3 139.03 257.77 2.5a8.51 8.51 0 0 0-6.04-2.5H46.93a8.54 8.54 0 0 0-8.53 8.53v332.8a8.54 8.54 0 0 0 17.07 0V17.07H243.2v128a8.54 8.54 0 0 0 8.53 8.53h128v76.8a8.54 8.54 0 0 0 17.07 0v-85.33a8.5 8.5 0 0 0-2.5-6.04zm-134.03-2.5V29.13l107.4 107.4h-107.4z" />
                  </svg>
                  <span className="jump-to-resources-title">Downloadable<br />Resources</span>
                </a>
              ) : ''}
              {parse(post.content)}

              {post.transcript && (
                <details className="transcript-details">
                  <summary className="transcript-summary">Video transcript</summary>
                  <div className="transcript-body">
                    <p>{parse(post.transcript)}</p>
                  </div>
                </details>
              )}

            </section>
          )}

          <footer className="metadata">
            <p>
              Published: {post.date}<br />
              Length: {post.videoLength}
            </p>
          </footer>

        </article>
      </PageSection>

      {post.videoResourceList && post.videoResourceList.length > 0 ? (
        <PageSection
          title="Resources available to download"
          sectionId="resources"
        >
          <div className="video-resources-grid">
            {post.videoResourceList.map(listItem => {
              return (
                <div className="card video-resource-card" key={listItem.id}>
                  <h5 className="card-header">{listItem.title}</h5>
                  <div className="card-body">
                    {listItem.description ? (<p className="card-text">{listItem.description}</p>) : ''}
                  </div>
                  <div className="card-footer text-muted">
                    <span>{listItem.ext} {listItem.filesize}</span>
                    <a className="btn btn-primary" href={listItem.url} target="_blank" rel="noreferrer">Download</a>
                  </div>
                </div>
              )
            })}
          </div>
        </PageSection>
      ) : ''}

      {similarPosts && similarPosts.length > 0 ? (
        <PageSection title="Similar videos" sectionClass="video-slider-padding">
          <VideoSlider videos={similarPosts} format="rating"></VideoSlider>
        </PageSection>
      ) : ''}

    </Layout>
  )
}

export default BlogPostTemplate


export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $categoryDatabaseId: Int
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      videoExcerpt
      content
      title
      videoUrl 
      vimeoPlayerUrl
      videoLength
      videoSubtitles
      cardImage
      cardImageSrcset
      date(formatString: "MMMM DD, YYYY")
      transcript
      featuredImage {
          node {
            sourceUrl
            srcSet
          }
      }
      videoResourceList {
        id
        title
        description
        url
        ext
        filesize
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }

    similarPosts: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
        id: { ne: $id }
      }
      sort: { fields: date, order: DESC }
      limit: 6
    ) {
      nodes {
        videoUrl
        cardImage
        cardImageSrcset
        featuredImage {
          node {
            sourceUrl
            srcSet
          }
        }
        title
        excerpt
        videoExcerpt
        slug
        id
        date
        featured
        categories {
          nodes {
            databaseId
          }
        }
      }
    }
  }
`
